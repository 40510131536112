import { ChipColor } from '@chordco/component-library'
import { Role } from 'types'

interface UserRoleDisplay {
  text: 'Team Admin' | 'Order Admin' | 'Member' | 'Super Admin' | 'Undefined'
  color: ChipColor
  variant: 'outlined' | 'solid' | 'solid-secondary'
}

export const useUserRoleStateDisplay = (userRole: Role): UserRoleDisplay => {
  switch (userRole) {
    case 'admin':
      return {
        text: 'Team Admin',
        color: 'secondary',
        variant: 'solid-secondary',
      }
    case 'oms_admin':
      return {
        text: 'Order Admin',
        color: 'lightLimeGreen',
        variant: 'solid-secondary',
      }
    case 'no_role':
      return {
        text: 'Member',
        color: 'neutral',
        variant: 'solid-secondary',
      }
    case 'superuser':
      return {
        text: 'Super Admin',
        color: 'lightpink',
        variant: 'solid-secondary',
      }
    default:
      // This should never happen because UserRole is strictly typed. Added for consistency.
      return {
        text: 'Undefined',
        color: 'neutral',
        variant: 'solid-secondary',
      }
  }
}
