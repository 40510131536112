import React from 'react'
import { standardIcons, AlertModal } from '@chordco/component-library'
import { usePublishDashboard } from './usePublishDashboard'

const { AlertWarning } = standardIcons

type PublishConfirmationModalProps = {
  dashboardName
  dashboardDescription
  dashboardSlug
  onCancel: () => void
  onPublish: () => void
}

export const PublishConfirmationModal: React.FC<
  PublishConfirmationModalProps
> = ({
  dashboardName,
  dashboardDescription,
  dashboardSlug,
  onCancel,
  onPublish,
}) => {
  const { publish, isLoading } = usePublishDashboard(onPublish)

  const handlePublish = async () => {
    await publish({
      dashboardName,
      dashboardDescription,
      dashboardSlug,
      replaceExisting: true,
    })
  }

  return (
    <AlertModal
      onClose={onCancel}
      icon={AlertWarning}
      content={`A dashboard with the name '${dashboardName}' already exists.`}
      subContent="You can overwrite the existing dashboard. Or simply cancel and re-publish your dasboard under a different name."
      centered
      buttons={[
        {
          id: 1,
          text: 'Cancel',
          onClick: onCancel,
          purpose: 'tertiary',
          disabled: false,
        },
        {
          id: 2,
          text: 'Yes, overwrite',
          onClick: handlePublish,
          purpose: 'destructive',
          isLoading: isLoading,
          disabled: false,
        },
      ]}
    />
  )
}
