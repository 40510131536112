import React, { useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import {
  DefaultControl,
  Dropdown,
  Button,
  standardIcons,
} from '@chordco/component-library'
import { UserDashboardEmbed } from './UserDashboardEmbed'
import { useCreateDashboard } from './useCreateDashboard'
import { useForm } from 'hooks'
import { CreateDashboardModal } from './CreateDashboardModal'
import { AvailableUserReport } from 'types'
import { ShareDashboardModal } from './ShareDashboardModal'
import { CopyDashboardModal } from './CopyDashboardModal'
import { SetDefaultDashboardModal } from './SetDefaultDashboardModal'
import { useAuthData } from 'redux/state/auth'
import { PublishDashboardModal } from './PublishDashboardModal'
import { PublishConfirmationModal } from './PublishConfirmationModal'

type MyFolderProps = {
  slug?: string
  options: AvailableUserReport[]
  onDeletedDashboard: () => void
  onPublishedDashboard: () => void
  setDefaultEnabled: boolean
}

const getLinkFromSlug = (slug: string) => `/analytics/my_folder/${slug}`

export const MyFolder: React.FC<MyFolderProps> = ({
  slug,
  options,
  onDeletedDashboard,
  onPublishedDashboard,
  setDefaultEnabled = false,
}) => {
  const dropdownOptions = options.map(o => ({
    id: o.slug,
    label: o.label,
  }))

  const selectedOption = !slug ? options[0] : options.find(o => o.slug === slug)

  if (!selectedOption) return null

  const history = useHistory()
  const onSelect = (slug: string) => history.push(getLinkFromSlug(slug))
  const selectedValue = selectedOption.label

  const [showAddNewModal, setShowAddNewModal] = useState(false)
  const [showShareModal, setShowShareModal] = useState(false)
  const [showImportModal, setShowImportModal] = useState(false)
  const [showSetDefaultModal, setShowSetDefaultModal] = useState(false)
  const [showPublishModal, setShowPublishModal] = useState(false)
  const [showNameAlreadyTaken, setShowNameAlreadyTaken] = useState<
    | {
        dashboardName: string
        dashboardDescription: string
        dashboardSlug: string
      }
    | undefined
  >(undefined)

  const handleAddNew = () => {
    setShowAddNewModal(true)
  }

  const handleShare = () => {
    setShowShareModal(true)
  }

  const handleImport = () => {
    setShowImportModal(true)
  }

  const handleSetDefault = () => {
    setShowSetDefaultModal(true)
  }

  const handlePublishDashboard = () => {
    setShowPublishModal(true)
  }

  const handleNameAlreadyTaken = (data: {
    dashboardName: string
    dashboardDescription: string
    dashboardSlug: string
  }) => {
    setShowNameAlreadyTaken(data)
  }

  const onCreated = (dashboard?: AvailableUserReport) => {
    setShowAddNewModal(false)

    if (dashboard) {
      history.push(getLinkFromSlug(dashboard.slug))
    }
  }

  const onCopied = (dashboard?: AvailableUserReport) => {
    setShowImportModal(false)

    if (dashboard) {
      history.push(getLinkFromSlug(dashboard.slug))
    }
  }

  const { submit, isLoading } = useCreateDashboard(onCreated)
  const { fields, onSubmit } = useForm(
    {
      title: {
        label: 'Title',
        type: 'string',
        initialValue: '',
        required: true,
      },
      folderId: {
        label: 'Folder ID',
        type: 'number',
        initialValue: selectedOption.folderId,
        required: true,
      },
    },
    submit
  )

  const {
    state: { user },
  } = useAuthData()

  const isAdmin = user?.role === 'admin' || user?.role === 'superuser'

  return (
    <Wrapper>
      <Top>
        <Dropdown
          options={dropdownOptions}
          onSelect={onSelect}
          selected={slug}
          control={<DefaultControl value={selectedValue} customWidth="198px" />}
        />

        <RightButtons>
          <Button
            purpose="tertiary"
            icon={standardIcons.Send}
            onClick={handleShare}
          >
            Share
          </Button>

          <Button
            purpose="tertiary"
            icon={standardIcons.Import}
            onClick={handleImport}
          >
            Import
          </Button>

          {isAdmin && setDefaultEnabled && (
            <Button
              purpose="tertiary"
              icon={standardIcons.Integration}
              onClick={handleSetDefault}
            >
              Set Default
            </Button>
          )}

          {isAdmin && (
            <Button
              purpose="tertiary"
              icon={standardIcons.Publish}
              onClick={handlePublishDashboard}
            >
              Publish
            </Button>
          )}

          <Button
            purpose="primary"
            icon={standardIcons.Plus}
            onClick={handleAddNew}
          >
            New Report
          </Button>
        </RightButtons>
      </Top>
      <EmbedContainer>
        <UserDashboardEmbed
          dashboardId={selectedOption.slug}
          onDeletedDashboard={onDeletedDashboard}
        />
      </EmbedContainer>

      {showAddNewModal && (
        <CreateDashboardModal
          fields={fields}
          onSubmit={onSubmit}
          onClose={() => setShowAddNewModal(false)}
          isLoading={isLoading}
        />
      )}

      {showShareModal && (
        <ShareDashboardModal
          link={`${window.location.origin}${getLinkFromSlug(
            selectedOption.slug
          )}`}
          onClose={() => setShowShareModal(false)}
        />
      )}

      {showImportModal && selectedOption.folderId && (
        <CopyDashboardModal
          folderId={selectedOption.folderId}
          onClose={() => setShowImportModal(false)}
          onCopied={onCopied}
        />
      )}

      {showSetDefaultModal && (
        <SetDefaultDashboardModal
          dashboardSlug={selectedOption.slug}
          onClose={() => setShowSetDefaultModal(false)}
        />
      )}

      {showPublishModal && (
        <PublishDashboardModal
          dashboardName={selectedOption.label}
          dashboardSlug={selectedOption.slug}
          onClose={() => setShowPublishModal(false)}
          onPublish={onPublishedDashboard}
          onNameAlreadyTaken={handleNameAlreadyTaken}
        />
      )}

      {showNameAlreadyTaken && (
        <PublishConfirmationModal
          dashboardName={showNameAlreadyTaken.dashboardName}
          dashboardDescription={showNameAlreadyTaken.dashboardDescription}
          dashboardSlug={showNameAlreadyTaken.dashboardSlug}
          onCancel={() => setShowNameAlreadyTaken(undefined)}
          onPublish={onPublishedDashboard}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100vh;
  padding: 0 64px 0 64px;
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
`

const RightButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const EmbedContainer = styled.div`
  border-radius: 0 0 0 ${p => p.theme.PageRadius};
  border-top: 1px solid ${p => p.theme.BorderOptimal};
  height: 100%;
`
