import { useLookerData } from 'redux/state/looker'
import { useState } from 'react'
import { PublishDashboardResponse } from 'types'

interface PublishDashboardData {
  dashboardName: string
  dashboardDescription: string
  dashboardSlug: string
  replaceExisting?: boolean
}

export const usePublishDashboard = (onPublish: () => void) => {
  const { publishDashboard } = useLookerData()

  const [isLoading, setIsLoading] = useState(false)
  const [hasFailed, setHasFailed] = useState(false)
  const [hasSucceeded, setHasSucceeded] = useState(false)

  const publish = async ({
    dashboardName,
    dashboardDescription,
    dashboardSlug,
    replaceExisting = false,
  }: PublishDashboardData) => {
    if (!publishDashboard) return

    setIsLoading(true)
    setHasSucceeded(false)
    setHasFailed(false)

    try {
      const response = (await publishDashboard(
        dashboardName,
        dashboardDescription,
        dashboardSlug,
        replaceExisting
      )) as PublishDashboardResponse

      if (!response.error) {
        setHasSucceeded(true)
        onPublish()
      } else {
        setHasFailed(true)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error: ', error)
      setHasFailed(true)
    } finally {
      setIsLoading(false)
    }
  }

  return { publish, isLoading, hasSucceeded, hasFailed }
}
