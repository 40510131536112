import React from 'react'
import styled from 'styled-components'
import { Button, Label, Modal } from '@chordco/component-library'
import { FormInput, FormTextArea } from 'components/common'
import { usePublishDashboard } from './usePublishDashboard'
import { useForm } from 'hooks'
import { useValidateDashboardName } from './useValidateDashboardName'
import { valTypes } from 'validationSchemas'

interface PublishDashboardModalProps {
  dashboardName: string
  dashboardSlug: string
  onClose: () => void
  onPublish: () => void
  onNameAlreadyTaken: (data: {
    dashboardName: string
    dashboardDescription: string
    dashboardSlug: string
  }) => void
}

export const PublishDashboardModal: React.FC<PublishDashboardModalProps> = ({
  dashboardName,
  dashboardSlug,
  onClose,
  onPublish,
  onNameAlreadyTaken,
}) => {
  const { validate } = useValidateDashboardName()
  const { publish, isLoading } = usePublishDashboard(onPublish)

  const submit = async () => {
    const dashboardData = {
      dashboardName: fields.dashboardName.value.toString(),
      dashboardDescription: fields.dashboardDescription.value.toString(),
      dashboardSlug: fields.dashboardSlug.value.toString(),
    }

    const isValid = await validate(dashboardData.dashboardName)

    return isValid
      ? await publish({ ...dashboardData, replaceExisting: false })
      : onNameAlreadyTaken(dashboardData)
  }

  const schema = {
    dashboardName: {
      label: 'Report Name',
      type: valTypes.string,
      initialValue: dashboardName,
      required: true,
    },
    dashboardDescription: {
      label: 'Description',
      type: valTypes.string,
      initialValue: '',
      required: false,
    },
    dashboardSlug: {
      label: 'Slug',
      type: valTypes.string,
      initialValue: dashboardSlug,
      required: true,
    },
  }

  const { fields, onSubmit } = useForm(schema, submit)

  return (
    <Modal
      onClose={onClose}
      title="Publish to Reports"
      width="480px"
      closeOnClickOutside={false}
    >
      <form onSubmit={onSubmit} noValidate>
        <Section>
          <StyledLabel>
            Publish your custom report to make it accessible to your entire
            team. Once published, the report will appear in the Analytics &gt;
            Reports section.
          </StyledLabel>
          <FormInput field={fields.dashboardName} customWidth="100%" required />
          <FormTextArea
            field={fields.dashboardDescription}
            customWidth="100%"
          />
        </Section>

        <BottomSection>
          <Button purpose="tertiary" onClick={onClose}>
            Cancel
          </Button>

          <Button type="submit" purpose="primary" isLoading={isLoading}>
            Publish
          </Button>
        </BottomSection>
      </form>
    </Modal>
  )
}

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledLabel = styled(Label)`
  white-space: pre-wrap;
`

const BottomSection = styled.div`
  width: 100%;
  padding: 16px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`
